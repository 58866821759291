<template>
  <div>
    <div class="footer-top-space"></div>
    <div class="footer site-footer">
      <div class="footer-content">
        
        <div style="float:left;width:50%;" class="footer-left"> 
          
        <div>
          <b>Categories</b>
          <br><br>
          <a href="/"><strong>CBT Thinking Errors</strong></a><br>
          <a href="/#cd"><strong>Cognitive Distortions</strong></a><br>
          <a href="/#lf"><strong>Logical Fallacies</strong></a><br>
          <a href="/#je"><strong>Judgment Errors</strong></a><br>
          <a href="/#stt"><strong>Social Thinking Traps</strong></a><br>
          <a href="/#ep"><strong>Emotional Pitfalls</strong></a><br>
          <a href="/#pb"><strong>Perceptual Biases</strong></a><br>
          <a href="/#bt"><strong>Behavioral Traps</strong></a><br>
          <a href="/#rb"><strong>Reasoning Bugs</strong></a>
          <!-- <a href="#md"><strong>Memory Distortions</strong></a> - Errors related to memory recall or retention, affecting how we remember events or information.<br><br>
          <a href="#dmf"><strong>Decision-Making Fallacies</strong></a> - Mistakes made during the decision-making process, often leading to poor outcomes. -->
        </div>
      </div>

        <div style="float:right;width:50%;text-align:left;" class="footer-right">
          <b>Thinking Bugs</b>
          <br><br>
          
          <router-link to="/about"><strong>About</strong></router-link><br>
          <router-link to="/cbt-app"><strong>CBT App</strong></router-link><br>


          <!-- <div style="height:25px;"></div> -->

<!-- Newsletter -->
<!-- <div class="newsletter">
  <b>Newsletter</b>
  
  <div style="height:10px;"></div>
  <span id="submit-component">
    <input type="text" id="submit-input" placeholder="Enter your email">
    <br>
    <button @click="subscribeButton" id="subs-button">Subscribe</button>
  </span>
  <div id="feedback"></div>
</div> -->


          <div style="clear:both;height:40px;"></div>

          <div style="width:30px;height:30px;margin-top:-5px;margin-bottom:-5px;float:left;" class="buttons-hover">
            <a href="https://twitter.com/thinkingbugs" title="Twitter" target="_blank">
              <svg class="svg-button"  viewBox="0 0 124.11 124.11">
                <path  d="M199.05,335a62.05,62.05,0,1,0,62.05,62.05A62.05,62.05,0,0,0,199.05,335h0Zm46.81,39.49a35.82,35.82,0,0,1-10.3,2.82,18,18,0,0,0,7.88-9.92,35.93,35.93,0,0,1-11.39,4.35,17.94,17.94,0,0,0-30.55,16.35,50.9,50.9,0,0,1-37-18.74,18,18,0,0,0,5.55,23.94,17.87,17.87,0,0,1-8.12-2.24c0,0.07,0,.15,0,0.22a18,18,0,0,0,14.38,17.58,17.95,17.95,0,0,1-8.09.31A18,18,0,0,0,185,421.62,36.16,36.16,0,0,1,158.48,429,50.74,50.74,0,0,0,186,437.1c33,0,51-27.32,51-51q0-1.17-.05-2.32a36.45,36.45,0,0,0,8.94-9.28h0Z" transform="translate(-137 -335)"/>
              </svg>
            </a>
          </div>

          <div style="clear:both;height:20px;"></div>
          Thinking Bugs © 2020-2025
        </div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      homePath: '/',
    }
  },
  methods: {      
    menuClose: function () {
      this.$parent.menuOpen = false;
    },
    menuClick: function () {
      this.$parent.menuOpen = true;
      window.scrollTo(0, 0);
    },
    toTop: function() {
      window.scrollTo(0,0);
      if (this.$parent.menuOpen) {
        this.$parent.menuOpen = false;
        this.$router.push(this.homePath);
      }
    },
    
    subscribeButton: function() {
      var email = document.getElementById("submit-input").value;
      var type = 'tb';
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/mailing-list/server.php', true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      
      xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          var response = xhr.responseText;
          var feedback = document.getElementById("feedback");
          
          if (response === "email error") {
            feedback.innerHTML = "<span style='color:red;'><br>Error: Invalid e-mail address</span><br>";
          } else if (response === "error") {
            feedback.innerHTML = "<b style='color:red;'>Error, please double-check the data you have entered</b><br>";
          } else if (response === "success") {
            document.getElementById("submit-component").style.display = "none";
            feedback.innerHTML = "<b style='color:#00c4ff;'>You are in!</b>";
          }
        }
      };
      
      xhr.send("action=subscribe&email=" + encodeURIComponent(email) + "&type=" + encodeURIComponent(type));
    }
  }
};
</script>

<style>

.svg-button {
  /* fill:#6a6b6c; */
  fill:#878787;
  fill-rule:evenodd;
  cursor:pointer;
  transition: fill 0.2s ease;
}

.buttons-hover:active .svg-button {
  fill:#ababab;
}

@media screen and (min-width: 600px) {
  .buttons:hover .svg-button {
    fill:#ababab;
  }
  .buttons-hover:hover .svg-button {
    fill:#ababab;
  }
}

.buttons:active .svg-button {
  fill:#ababab;
}

.buttons-hover:active .svg-button {
  fill:#ababab;
}


  .footer {
    width: 100%;
    background-color:#444;
    left: 0;
    padding-top: 35px;
    padding-bottom: 45px;
    min-height: 70px;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }

  .footer-link {
    color: #e7e7e7!important;
  }

  @media only screen and (max-width: 600px) {
    .footer {
      padding-left:5px;
      padding-right:6px;
    }
  }

  .footer-content {
    max-width: 640px;
    margin: 0 auto;
  }

  .footer-top-space {
    /* height: 20px; */
  }

  @media only screen and (max-width: 680px) {
    .footer-content {
      padding-left:25px;
      padding-right:25px;
    }
    .footer-top-space {
      /* height: 10px; */
    }
  }

  @media only screen and (max-width: 450px) {
    .footer-right {
      clear:both;
      float:left!important;
      margin-top:40px;
      width:100%!important;
    }
    .footer-left {
      width:100%!important;
    }
  }

  .footer-column {
    width: 310px;
    float: left;
  }

  .footer {
    font-size: 14px;
    color: rgb(226, 226, 226);
  }

  .footer a {
    font-size: 14px;
    color: rgb(226, 226, 226);
    text-decoration: underline;
    line-height:22px;
    border-bottom: 0px!important;
  }
  .footer a, .footer a:visited {
    color: #deffcfbd!important;
}

.footer a:active, .footer a:hover {
    color: #c8ffaf!important;
    text-decoration:none!important;
}



.newsletter {
            margin: 20px auto;
            /* text-align: center; */
        }

        .newsletter input {
            border: none;
            /* border-bottom: 2px solid #000000; */
            outline: none;
            font-size: 1em;
            padding: 8px 5px;
            min-width: 190px;
            /* width: 300px;
            text-align: center; */
            transition: border-color 0.3s;
            background: #6c6c6c;
            color:rgb(250, 250, 250);
        }

        .newsletter input:focus {
            border-color: #888888;
        }

        .newsletter button {
            margin-top: 15px;
            padding: 10px 30px;
            font-size: 1em;
            background-color: #222;
            color: #ffffff;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s;
            letter-spacing: 1px;
        }

        .newsletter button:hover {
            background-color: #333;
            outline: 1px solid black;
        }

</style>