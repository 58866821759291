import { render, staticRenderFns } from "./MenuBottomDMF.vue?vue&type=template&id=4a08ac34&scoped=true"
import script from "./MenuBottomDMF.vue?vue&type=script&lang=js"
export * from "./MenuBottomDMF.vue?vue&type=script&lang=js"
import style0 from "./MenuBottomDMF.vue?vue&type=style&index=0&id=4a08ac34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a08ac34",
  null
  
)

export default component.exports