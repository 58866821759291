<template>
  <div>
    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Reasoning Bugs</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Reasoning Bugs</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Reasoning bugs are subtle errors in logic and critical thinking that can significantly undermine sound reasoning and decision-making. These cognitive missteps often occur without us realizing, leading to flawed conclusions or reinforcing biases. By identifying and addressing these reasoning bugs, we can improve our ability to think clearly, make more rational decisions, and avoid common pitfalls in our logic.
      <div style="height:40px;"></div>
    </div>

    <!-- Congruence Bias -->
    <div v-if="current != 'CB'">
      <router-link to="/congruence-bias">
        <div class="footer-div">
          CB
        </div>
        <div class="footer-div-label">
          Congruence Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You tend to test hypotheses in a way that confirms your initial expectations.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Congruence Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Blind Spot Bias -->
    <div v-if="current != 'BSB'">
      <router-link to="/blind-spot-bias">
        <div class="footer-div">
          BSB
        </div>
        <div class="footer-div-label">
          Blind Spot Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You recognize the impact of biases on others but fail to see how they influence your own thinking.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        BSB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Blind Spot Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- System 1 and System 2 Thinking -->
    <div v-if="current != 'S12'">
      <router-link to="/system-1-and-system-2-thinking">
        <div class="footer-div">
          S12
        </div>
        <div class="footer-div-label">
          System 1 and System 2 Thinking
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You switch between fast, intuitive thinking and slow, deliberate reasoning.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        S12
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        System 1 and System 2 Thinking
      </div>
    </div>

    <div class="footer-div-spacer"></div>
    
    <!-- Semmelweis Reflex -->
    <div v-if="current != 'SR'">
      <router-link to="/semmelweis-reflex">
        <div class="footer-div">
          SR
        </div>
        <div class="footer-div-label">
          Semmelweis Reflex
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You reject new information or evidence simply because it contradicts established norms or beliefs.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SR
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Semmelweis Reflex
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
