<template>
  <div>
    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Perceptual Biases</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Perceptual Biases</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Perceptual biases are systematic errors in how we perceive and interpret the world around us. These biases often result in distortions of reality, leading to misunderstandings, faulty judgments, or illusions. Our brain's tendency to filter and prioritize certain types of information over others can cause us to draw incorrect conclusions or see things that are not truly there. By identifying and understanding these biases, we can work towards a more accurate and balanced perception of the world, improving our decision-making and interactions with others.
      <div style="height:40px;"></div>
    </div>

    <!-- Fundamental Attribution Error -->
    <div v-if="current != 'FAE'">
      <router-link to="/fundamental-attribution-error">
        <div class="footer-div">
          FAE
        </div>
        <div class="footer-div-label">
          Fundamental Attribution Error
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overemphasize personal characteristics and ignore situational factors in judging others' behavior.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        FAE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Fundamental Attribution Error
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Contrast Effect -->
    <div v-if="current != 'CE'">
      <router-link to="/contrast-effect">
        <div class="footer-div">
          CE
        </div>
        <div class="footer-div-label">
          Contrast Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        Your perception of something is influenced by comparisons to other things.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        CE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Contrast Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>
    
    <!-- Source Confusion -->
    <div v-if="current != 'SC'">
      <router-link to="/source-confusion">
        <div class="footer-div">
          SC
        </div>
        <div class="footer-div-label">
          Source Confusion
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You misattribute the source of a memory, confusing whether it was something you experienced, imagined, or heard from someone else.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SC
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Source Confusion
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Reactance Bias -->
    <div v-if="current != 'RB'">
      <router-link to="/reactance-bias">
        <div class="footer-div">
          RB
        </div>
        <div class="footer-div-label">
          Reactance Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You react against rules or restrictions, doing the opposite of what is intended.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        RB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Reactance Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Representativeness Heuristic -->
    <div v-if="current != 'RH'">
      <router-link to="/representativeness-heuristic">
        <div class="footer-div">
          RH
        </div>
        <div class="footer-div-label">
          Representativeness Heuristic
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You judge the probability of something based on how closely it matches a stereotype or prototype.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        RH
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Representativeness Heuristic
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Mere Exposure Effect -->
    <div v-if="current != 'MEE'">
      <router-link to="/mere-exposure-effect">
        <div class="footer-div">
          MEE
        </div>
        <div class="footer-div-label">
          Mere Exposure Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You develop a preference for something simply because you have been exposed to it repeatedly.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        MEE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Mere Exposure Effect
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>



<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
