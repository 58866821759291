<template>
  <div>
    <h1>About Thinking Bugs</h1>

<div style="height:20px;"></div>

<p>The Thinking Bugs website started in 2020 as a resource for people looking to improve their mental health and well-being through Cognitive Behavioral Therapy (CBT). Visitors could find descriptions and examples of classical CBT thinking errors, also known as cognitive distortions. These are common patterns of thinking that can misrepresent reality, often leading to negative emotions and behaviors.</p>

<p>Today, Thinking Bugs offers not only the classical CBT thinking errors but also additional cognitive distortions, or <a href="https://twitter.com/thinkingbugs/status/1182119140267569154" target="_blank">thinking bugs</a>, as we call them.</p>

<p>We use garden and computer metaphors to explain how these "bugs" can affect the mind and its functioning:</p>

<p><strong>Garden Metaphor:</strong> Bugs are like negative thoughts and behaviors that can take over and dominate the mind if not properly cared for. These "bugs" are similar to weeds that grow and spread in the garden of the mind, hindering the growth of positive thoughts and behaviors.</p>

<p><strong>Computer Metaphor:</strong> Bugs are compared to errors or glitches in a computer system that disrupt performance. In the mind, these "bugs" are negative thought patterns or thinking errors that interfere with optimal functioning. Just as a computer requires regular maintenance to fix bugs and ensure proper functioning, the mind needs care and attention to identify and address these negative patterns.</p>

<div style="height:25px;"></div>

<h1>CBT App</h1>

<div style="height:20px;"></div>

<p>To help users identify and overcome negative thought patterns, we created the <router-link to="/cbt-app" target="_blank">CBT App</router-link>, inspired by classical CBT journaling techniques. It's a tool for journaling and reframing thoughts in healthier, more constructive ways. By using the app, users can learn to recognize thinking bugs in their thoughts and take steps to rewrite and reframe them. Whether you're looking to learn more about cognitive distortions or actively improve your mental health through CBT techniques, the Thinking Bugs website and the journaling app are valuable resources.</p>

<p>The CBT App is an offline-first progressive web app (PWA), meaning it can be installed on most mobile and desktop devices and used without an internet connection.</p>

<p>The app is a modern, digital version of classical CBT practice, based on the <a href="https://feelinggood.com/tag/daily-mood-log/" target="_blank">mood logging technique</a> described and popularized by <a href="https://en.wikipedia.org/wiki/David_D._Burns" target="_blank">David D. Burns</a> and <a href="https://en.wikipedia.org/wiki/Aaron_T._Beck" target="_blank">Aaron T. Beck</a>.</p>

<p>The CBT App is privacy-focused by design. Your records are stored locally on your device and are never sent over the internet.</p>



<div style="height:25px;"></div>

<h1>Contact</h1>

<p>Contact us at <a href="mailto:hi@thinkingbugs.com">hi@thinkingbugs.com</a></p>

<div style="height:25px;"></div>
<p><strong>DISCLAIMER</strong></p>

<p style="text-transform:uppercase;">
  Some descriptions and examples of cognitive distortions on this website have been partially generated by the ChatGPT AI engine and subsequently reviewed by a human editor to ensure accuracy.
  <br><br>
  The ideas and suggestions provided are not intended to replace professional medical advice. Always consult your physician or a qualified healthcare provider regarding any matters related to your health.
</p>

<div style="height:10px;"></div>



    
  </div>
</template>

<script>
  import MenuBottom from '../components/MenuBottom.vue';
  import CBTAppText from '../components/CBTAppText.vue';

  export default {
    components: {
      MenuBottom,
      CBTAppText
    }
  }
</script>