<template>
  <div>
    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Judgment Errors</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Judgment Errors</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Judgment errors are cognitive biases that skew decision-making processes, often leading to incorrect or suboptimal outcomes. These biases influence how we interpret information, make predictions, and assess risks, frequently resulting in flawed judgments that can have significant impacts on our decisions and behaviors. Understanding these errors can help in making more rational and informed decisions, avoiding common pitfalls that many fall into.
      <div style="height:40px;"></div>
    </div>

    <!-- Gambler's Fallacy -->
    <div v-if="current != 'GF'">
      <router-link to="/gamblers-fallacy">
        <div class="footer-div">
          GF
        </div>
        <div class="footer-div-label">
          Gambler’s Fallacy
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You believe that past random events influence the likelihood of future ones.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        GF
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Gambler’s Fallacy
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Base Rate Neglect -->
    <div v-if="current != 'BRN'">
      <router-link to="/base-rate-neglect">
        <div class="footer-div">
          BRN
        </div>
        <div class="footer-div-label">
          Base Rate Neglect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You ignore general statistical information in favor of specific information.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        BRN
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Base Rate Neglect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Outcome Bias -->
    <div v-if="current != 'OB'">
      <router-link to="/outcome-bias">
        <div class="footer-div">
          OB
        </div>
        <div class="footer-div-label">
          Outcome Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You judge the quality of a decision based on its outcome rather than the process.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        OB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Outcome Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Misinformation Effect -->
    <div v-if="current != 'ME'">
      <router-link to="/misinformation-effect">
        <div class="footer-div">
          ME
        </div>
        <div class="footer-div-label">
          Misinformation Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        Your memories become less accurate due to post-event information.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        ME
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Misinformation Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Serial Position Effect -->
    <div v-if="current != 'SPE'">
      <router-link to="/serial-position-effect">
        <div class="footer-div">
          SPE
        </div>
        <div class="footer-div-label">
          Serial Position Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You are more likely to remember the first and last items in a series.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SPE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Serial Position Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Primacy Effect -->
    <div v-if="current != 'PE'">
      <router-link to="/primacy-effect">
        <div class="footer-div">
          PE
        </div>
        <div class="footer-div-label">
          Primacy Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You are more likely to remember the first items presented in a list.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        PE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Primacy Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Recency Effect -->
    <div v-if="current != 'RE'">
      <router-link to="/recency-effect">
        <div class="footer-div">
          RE
        </div>
        <div class="footer-div-label">
          Recency Effect
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You are more likely to remember the most recent items presented in a list.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        RE
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Recency Effect
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Hot-Hand Fallacy -->
    <div v-if="current != 'HHF'">
      <router-link to="/hot-hand-fallacy">
        <div class="footer-div">
          HHF
        </div>
        <div class="footer-div-label">
          Hot-Hand Fallacy
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You believe that a person who has experienced success is more likely to continue having success.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        HHF
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Hot-Hand Fallacy
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Planning Fallacy -->
    <div v-if="current != 'PF'">
      <router-link to="/planning-fallacy">
        <div class="footer-div">
          PF
        </div>
        <div class="footer-div-label">
          Planning Fallacy
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You underestimate the time, costs, and risks of future actions while overestimating the benefits.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        PF
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Planning Fallacy
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Pro-Innovation Bias -->
    <div v-if="current != 'PIB'">
      <router-link to="/pro-innovation-bias">
        <div class="footer-div">
          PIB
        </div>
        <div class="footer-div-label">
          Pro-Innovation Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overvalue an innovation's benefits while underestimating its limitations or risks.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        PIB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Pro-Innovation Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Overconfidence Bias -->
    <div v-if="current != 'OCB'">
      <router-link to="/overconfidence-bias">
        <div class="footer-div">
          OCB
        </div>
        <div class="footer-div-label">
          Overconfidence Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You overestimate your own abilities or knowledge.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        OCB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Overconfidence Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>
    
    <!-- Zero-Risk Bias -->
    <div v-if="current != 'ZRB'">
      <router-link to="/zero-risk-bias">
        <div class="footer-div">
          ZRB
        </div>
        <div class="footer-div-label">
          Zero-Risk Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You prefer to eliminate a small risk entirely rather than reducing a larger risk.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        ZRB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Zero-Risk Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Neglect of Probability -->
    <div v-if="current != 'NP'">
      <router-link to="/neglect-of-probability">
        <div class="footer-div">
          NP
        </div>
        <div class="footer-div-label">
          Neglect of Probability
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You disregard probability when making a decision, focusing on the outcome instead.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        NP
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Neglect of Probability
      </div>
    </div>

    <div class="footer-div-spacer"></div>
    
    <!-- Motivated Reasoning -->
    <div v-if="current != 'MR'">
      <router-link to="/motivated-reasoning">
        <div class="footer-div">
          MR
        </div>
        <div class="footer-div-label">
          Motivated Reasoning
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You rationalize your decisions based on what you want to believe.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        MR
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Motivated Reasoning
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Survivorship Bias -->
    <div v-if="current != 'SB'">
      <router-link to="/survivorship-bias">
        <div class="footer-div">
          SB
        </div>
        <div class="footer-div-label">
          Survivorship Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You focus on successful cases while ignoring failures, leading to overestimation of success.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Survivorship Bias
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>



<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
