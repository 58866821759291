<template>
  <div>

    <div style="height:20px;"></div>

    <h4 v-if="home !== true">Emotional Pitfalls</h4>
    <h1 v-else style="margin-bottom:40px;margin-top:60px;">Emotional Pitfalls</h1>

    <div v-if="home === true" style="font-size:15px;margin-top:30px;" class="links-content">
      Emotional pitfalls are cognitive distortions that arise when our emotions overpower our rational thinking, leading to decisions that are not based on logic or reality. These thinking errors are often driven by intense emotions such as anger, fear, or pride, which can cloud our judgment and make us perceive situations inaccurately. By recognizing these emotional pitfalls, we can learn to manage our emotions more effectively and make better, more rational decisions.
      <div style="height:40px;"></div>
    </div>

    <!-- Self-serving Bias -->
    <div v-if="current != 'SSB'">
      <router-link to="/self-serving-bias">
        <div class="footer-div">
          SSB
        </div>
        <div class="footer-div-label">
          Self-serving Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You attribute your successes to internal factors and your failures to external factors.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        SSB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Self-serving Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Visceral Bias -->
    <div v-if="current != 'VB'">
      <router-link to="/visceral-bias">
        <div class="footer-div">
          VB
        </div>
        <div class="footer-div-label">
          Visceral Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        Strong emotions affect your judgment, leading to skewed perceptions or decisions.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        VB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Visceral Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>

    <!-- Ego Bias -->
    <div v-if="current != 'EB'">
      <router-link to="/ego-bias">
        <div class="footer-div">
          EB
        </div>
        <div class="footer-div-label">
          Ego Bias
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        Your inflated sense of self-importance distorts your judgment and decision-making.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        EB
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Ego Bias
      </div>
    </div>

    <div class="footer-div-spacer"></div>
    
    <!-- Wishful Thinking -->
    <div v-if="current != 'WT'">
      <router-link to="/wishful-thinking">
        <div class="footer-div">
          WT
        </div>
        <div class="footer-div-label">
          Wishful Thinking
        </div>
      </router-link>
      <div v-if="home === true" class="footer-div-description">
        You believe something is true because you want it to be true.
      </div>
    </div>
    <div v-else>
      <div class="footer-div footer-div-fixed">
        WT
      </div>
      <div class="footer-div-label footer-div-label-inactive">
        Wishful Thinking
      </div>
    </div>

    <div style="clear:both;height:5px;"></div>

  </div>
</template>




<script>
export default {
  data: function() {
    return {}
  },

  methods: {
    resizeTextarea () {},
  },

  mounted () {
    // console.log(this.current);
  },

  beforeDestroy () {},
  render () {},

  props: [ 'current', 'home' ]
}
</script>

<style scoped>
  .footer-div {
    margin-right: 5px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    float: left;
    width: 32px;
    height: 29px;
    border-radius: 50%;
    color: black;
    display: inline-table;
    padding-top: 4px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: #ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
    font-weight: bold;
  }

  .footer-div:hover + .footer-div-label {
    text-decoration: underline;
  }

  .footer-div-fixed {
    background-color: #c8ffaf!important;
    border: 1px solid #26e200!important;
  }

  .footer-div-label {
    position:absolute;
    margin-left:44px;
    margin-top:2px;
    font-weight: bold;
  }

  .footer-div-description {
    margin-left: 44px;
    font-size: 15px;
    margin-top: 3px;
    padding-top:24px;
    color: #555;
  }

  .footer-div-spacer-home {
    clear:both;
    height:23px;
  }

  .footer-div-spacer {
    clear:both;
    height:15px;
  }

  .footer-div-label:hover {
    text-decoration: underline;
  }

  .footer-div-label-inactive {
    text-decoration: none!important;
  }
</style>
